
export const profile = (state = null, action) => {
    if (action.type === "SET_PROFILE") {
        state = {...state, profileData: action.payload}
        return state;
    } else if(action.type === "GET_PROFILE") {
        return state;
    }
    return state;
};

export default profile;