
export const auth = (state = null, action) => {
    if (action.type === "SET_AUTH_STATUS") {
        state = {...state, isAuth: action.payload}
        return state;
    } else if(action.type === "CHECK_AUTH_STATUS") {
        return state;
    }
    return state;
};

export default auth;