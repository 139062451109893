
export const setProfile = (profileData) => {
    return {
        type: 'SET_PROFILE',
        payload: profileData
    }
}

export const getProfile = () => {
    return {
        type: 'GET_PROFILE',
    }
}