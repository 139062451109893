

const loading = (loading) => {
    if (loading) {
        return {
            type: 'START_LOADING',
            payload: loading
        }
    } else {
        return {
            type: 'STOP_LOADING',
            payload: loading
        }
    }
}

export default loading;