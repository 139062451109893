
export const setAuthStatus = (status) => {
    return {
        type: 'SET_AUTH_STATUS',
        payload: status
    }
}

export const checkAuthStatus = () => {
    return {
        type: 'CHECK_AUTH_STATUS',
    }
}