
const loading = (state=null, action) => {
    if (action.type === "START_LOADING") {
        return action.payload;
    } else if(action.type === "STOP_LOADING") {
        return action.payload;
    }
    return state;
};

export default loading;